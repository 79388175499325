import React from 'react'
import popupImage from '..//..//Assets/popupImage.png'

export default function ThanksPage() {
  return (
    <div className="font-[sentient] w-full  flex flex-col gap-2 justify-center items-center text-3xl text-red- mt-40 font-bold">
      <p>Thank you for contacting Shri Lakshmi Water Purifiers.</p>
      <p className="text-xl font-normal">Our team will connect with you shortly.</p>
      <div className=""><img className="h-[25rem] w-[30rem] rounded-xl" src={popupImage} alt="" /></div>
      <a className="text-xl underline text-blue-500" href="/">Go back to homepage</a>
    </div>
  )
}
