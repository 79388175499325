import React, { useEffect } from "react";
import Footer from "./Footer";

export default function ProductReturnPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="mt-40  ml-10 md:ml-20 mb-20" id="termsandconditions">
        <p className="font-[sentient] font-bold text-3xl text-skyhero">
          No-hassle Return and Replacement Policy
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          At Shri Lakshmi Water Purifiers, we believe in customer satisfaction
          and our responsibility to you doesn't end with your product purchase.
          With our impeccable service guarantee, we assist you with a no-hassle,
          easy return and replacement policy - be it refunds, quality checks, or
          cancellations - all your questions are answered below.
        </p>
        <p className="font-[sentient] font-bold text-3xl text-skyhero mt-4">
          Product Return & Warranty Policy
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          To deliver our promise of utmost customer satisfaction, we have a set
          of policies for quick returns, replacements, exchanges and
          cancellations that will help you get the best product for the
          investment made. Read more below.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          30-days money-back guarantee
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          We offer you a "No Questions Asked" return policy, to provide you with
          a worry-free shopping experience when you choose to make a full
          payment on srilakshmispares.com If you are unsatisfied with the item,
          you may conveniently return it in its original packaging for a refund.
        </p>
        <p className="font-[sentient]  text-md  mt-4 mb-6 px-8 w-[90%] text-gray-800">
          How can I return a product ordered from Shri Lakshmi water purifiers?
        </p>
        <ul className="font-[sentient]  text-sm   px-8 w-[90%] text-gray-500 flex flex-col gap-3">
          <li>
            Step1: Visit our site www.srilakshmispares.com and fill the form
            under support,
          </li>
          <li>
            Step2: Review your ticket, our service engineer visit your location
            and inspect the product{" "}
          </li>
          <li>
            Step 3: Keep the product ready along with its original packaging.
          </li>
          <li>
            Step4: Our representative will inspect the unit and arrange for the
            pick-up of the product from the place of delivery. In some rare
            cases, we will ask you to ship the product.
          </li>
          <li>
            Step 5: Will replace the product or refund the amount within 15-21
            working days.
          </li>
          <li className="text-red-500">
            How can I contact Shri Lakshmi water purifiers if there are any
            issues?
          </li>
          <li className="text-blue-500">
            Shri Lakshmi water purifiers customer care number +91-7795453579 or
            you can raise the support ticket on our official website
            www.srilakshmispares.com
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          Order cancellation
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          Once the order is placed, it cannot be cancelled unless acceptance of
          such cancellation is agreed by us. We reserve the right to accept or
          reject any order placed, without assigning any reasons whatsoever.
          Cancellation of orders is possible only before the order is invoiced
          for shipment.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          Replacement policy (in case of product manufacturing defects only)
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          To deliver utmost customer satisfaction, we have a set of policies for
          quick replacements that will help you get the best product for the
          investment made.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">Warranty</p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          If there is any manufacturing defect in the Product, the Company will
          repair or replace the defective parts or product within 12 months from
          the date of original purchase.
        </p>
      </div>
      <Footer />
    </>
  );
}
