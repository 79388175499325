import React from "react";

export default function Logocards({logo}) {
  return (
    <div className="h-[5rem] w-[12rem] md:h-[8rem] md:w-[18rem] bg-white shadow-lg rounded-xl blur-2 overflow-hidden flex items-center justify-center">
      <img
        className="aspect-square object-contain h-3/4 w-3/4 "
        src={logo.image}
        alt=""
      />
    </div>
  );
}
