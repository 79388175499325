import React from "react";

export default function Loader() {
  return (
    <div className="flex space-x-2 justify-center items-center h-screen">
      <span className="sr-only">Loading...</span>
      <div className="xl:text-xl lg:text-xl md:text-xl text-lg rounded-full animate-bounce [animation-delay:-0.3s]">
        Loading Products Please wait...
      </div>
      {/* <div className="h-6 w-6 bg-skyhero rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-6 w-6 bg-skyhero rounded-full animate-bounce"></div> */}
    </div>
  );
}
