import React from "react";

export default function Aboutus() {
  return (
    <div className="aboutus bg-skyhero w-full flex flex-col items-center justify-center py-16 gap-10 ">
      <p className="font-[sentient] text-white text-5xl font-bold">About us</p>
      <p className="w-[90%] md:w-1/2 font-[Epilogue] text-white text-center">
        BluLife is an innovative home solutions brand dedicated to enhancing
        your living experience. From top-quality water purifiers to essential
        kitchen tools and appliances, we strive to deliver high-performance
        products you can trust.
      </p>
    </div>
  );
}
