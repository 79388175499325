import React from "react";

export default function ProductsCard({ product }) {
  return (
    <div className="w-full flex rounded-2xl relative overflow-hidden">
      <div className="flex justify-around lg:ml-5 md:ml-5 items-center">
        <div className="rounded">
          <img
            className="lg:w-[600px] md:w-[400px] w-auto lg:p-5 md:p-5 rounded"
            src={product.image}
            alt=""
          />
        </div>
        <div className="mb-5 p-3">
          <p className="font-[sentient] lg:text-[18px] md:text-[10px] text-[8px] text-gray-700 font-medium">
            {product.title}
          </p>
          <div className="flex items-center gap-3">
            <p className="font-[sentient] lg:text-xl xl:text-xl md:text-xl text-md text-red-600 font-bold">
              ₹{product.discountedPrice}
            </p>
            <p className="font-[sentient] lg:text-xl xl:text-xl md:text-xl text-md text-neutral-400 line-through">
              ₹{product.price}
            </p>
            <p className=" font-[sentient] lg:text-lg xl:text-lg md:text-lg text-[8px] text-skyhero">
              {product.brand}
            </p>
          </div>
          <button className="bg-skyhero px-4 py-2 rounded mt-2 text-white font-semibold">
            Order Now
          </button>
        </div>
      </div>
    </div>
  );
}
