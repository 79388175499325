import React, { useState } from "react";
import ProductContext from "./Context";
import axios from "axios";

export default function Productsstate(props) {
  const [productss, setProducts] = useState([]);
  const [Loading , setLoading] = useState(false);

  const productList = async () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://waterpurifier-9b87.onrender.com/api/listProducts",
    };
    axios
      .request(config)
      .then((response) => {
      
        setProducts(response.data.data);
        // console.log(response.data.data);
        setLoading(false)
    
        
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          window.alert("error", error);
          setLoading(false)
        }
      });
  };

  return (
    <ProductContext.Provider value={{ productList, productss ,Loading }}>
      {props.children}
    </ProductContext.Provider>
  );
}
