import React, { useEffect } from "react";
import Footer from "./Footer";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="mt-40  ml-10 md:ml-20 mb-20">
        <p className="font-[sentient] font-bold text-3xl text-skyhero">
          Privacy Policy
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          1. INTRODUCTION
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            1.1 Shri Lakshmi Water Purifier is committed to ensuring privacy of
            the Users on its website (“Website”) and/or mobile application
            (“Mobile App”). In furtherance of the same, Shri Lakshmi Water
            Purifier has framed this Privacy Policy in accordance with the
            Information Technology Act, 2000 (“Act”) read with the applicable
            Rules thereunder.
          </li>
          <li>
            1.2 The Privacy Policy is designed to familiarize Users of this
            Website / Mobile App with (a) the type of information that Users may
            share with Shri Lakshmi Water Purifier, or that Shri Lakshmi Water
            Purifier will collect from the Users; (b) practices and policies of
            Shri Lakshmi Water Purifier relating to collection, storage,
            dealing, transfer, disclosure, transfer etc. of information
            pertaining to Users; (c) purpose of collection and usage of such
            information, and related matters.
          </li>
          <li>
            1.3 This Privacy Policy forms part of the Terms of Use of the
            Website / Mobile App
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          2. IMPORTANT TERMS
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            2.1 Please read the terms of this Privacy Policy carefully. This
            Privacy Policy, along with the “Terms of Use” constitute an
            “electronic record” in the form of an “electronic contract” as
            defined under the Information Technology Act, 2000 between Shri
            Lakshmi Water Purifier and the User of this Website / Mobile App.
            This Privacy Policy does not require any physical, electronic or
            digital signature
          </li>
          <li>
            2.2 This Privacy Policy forms an integral part of the “Terms of Use”
            of the Website / Mobile App. If You do not agree with the terms of
            this Privacy Policy, please do not use this Website / Mobile App. By
            visiting this Website / using the Mobile App, you (a)
            unconditionally accept, and agree to be bound by the “Terms of Use”
            of the Website/ Mobile App, read with this Privacy Policy; and (b)
            expressly consent to the collection, receipt, possession, storage,
            usage, dealing, handling or transfer of Your personal information by
            Shri Lakshmi Water Purifier in accordance with the terms of this
            Privacy Policy.
          </li>
          <li>
            2.3 The Privacy Policy can undergo a change at any time at the
            discretion of Shri Lakshmi Water Purifier. Therefore, it is
            suggested that You regularly check the Website / Mobile App to be
            apprised of the latest changes made to the Privacy Policy.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          3. TYPES OF USER INFORMATION
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            3.1 Collection of “Information”: Shri Lakshmi Water Purifier may
            request for, collect or gather such “Information” (which shall
            include data) as it deems necessary, relevant or incidental for
            rendering an improved shopping experience to Users and for other
            purposes as specified in Paragraph 6 below.
          </li>
          <li>
            3.2 Personal Information: Such “Information” may include Personal
            Information relating to Users such as (a) User’s name, address,
            telephone number, e-mail address, postal address, delivery address
            (if different), gender, purchase history etc. (b) financial
            information such as bank account or credit or debit card
            information; (c) physical condition of User; (d) a description of
            the item requested or purchased; (e) the internet protocol (“IP”)
            address of User’s computer; (f) any other “sensitive personal data
            or information” relating to the User as defined under the applicable
            Rules under the Act.
          </li>
          <li>
            3.3 Non-personal Information Shri Lakshmi Water Purifier may also
            collect certain non-personal Information from the User, when the
            User visits and / or uses the Website / Mobile App. Such
            non-personal Information would include but not be limited to
            information such as geographical location of User, IP address, type
            of browser, operating system of User’s device, and details of usage
            of Website / Mobile App etc. Such non-personal Information is
            collected through various ways including “cookies”. Cookies are text
            files placed on Your computer, to help the Website / Mobile App
            analyse how You use the Website / Mobile App. Such information is
            not used to track information about individuals, but is used on an
            aggregate level to customize our site, improve the online experience
            of the User and plan advertising and promotions.
          </li>
          <li>
            3.4 Cookies, Web Beacons and Tracking Codes: Cookies are small text
            files that a website downloads onto your computer or other
            internet-enabled devices (such as mobile phones and tablets) when
            you visit a website. The cookie will help the Website / Mobile App
            recognise your device the next time you visit. We may use both
            session cookies (disappear after you close your browser) and
            persistent cookies (remain after you close your browser and may be
            accessed every time you use or connect to our Website / Mobile App).
            We may collect information like user’s IP address, geographical
            location, browser/device type and version, operating system,
            referral source, device, length of visit, page views and website
            navigation paths, as well as information about the timing, frequency
            and pattern of user website use through "cookies". You can block
            cookies by activating the setting on your browser that allow you to
            refuse the setting of all or some cookies. However, if you use your
            browser settings to block all cookies (including essential cookies),
            you may not be able to access all or parts of our site, or they may
            not function properly.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          4. MODES OF COLLECTION OF USER INFORMATION
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            4.1 The information referred to above may be collected or received
            by Shri Lakshmi Water Purifier (a) directly from the User, when the
            User either provides any specific information on the Website /
            Mobile App; or (b) from use of the Website / Mobile App by the User
            (including through IoT device placed in the Shri Lakshmi Water
            Purifier product purchased by the User); or (c) from third parties
            who have collected any Information relating to the User, and who
            have shared it with Shri Lakshmi Water Purifier.
          </li>
          <li>
            4.2 There are times when You may provide information to us
            voluntarily, otherwise than through use of the Website / Mobile App.
            This occurs when You provide Your feedback to us through e-mails,
            return forms, letters or telephone calls. We may use this
            information to respond to Your query and to keep track of feedback
            received from You.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          5. PURPOSE OF COLLECTION AND USAGE OF INFORMATION
        </p>
        <p className="font-[sentient]  text-sm text-gray-500 mt-4">
          Shri Lakshmi Water Purifier may collect, store and use Information for
          any purpose as may be permissible under Applicable Laws, including but
          limited to the following:
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            (a) To display the User’s business listing or Product offerings
            across the Website / Mobile App to fetch maximum business
            opportunities for the User;
          </li>
          <li>
            (b) Facilitation of Your use of the Website / Mobile App, handling
            and execution of orders placed on the Website / Mobile App by the
            User, process payments, communication with User about orders etc.;
          </li>
          <li>(c) Respond to any inquiries posed by the User;</li>
          <li>
            (d) Provide User with details of important schemes, offers, Product
            details, new Product launches, changes to terms of use of the
            Website / Mobile App, order details, delivery details and make other
            important announcements etc. ;
          </li>
          <li>
            (e) Maintain accounts of the User, display contents inputted by User
            such as User profile, User wishlist etc.;
          </li>
          <li>
            (f) Help recommend to the User merchandise and services that may be
            of interest to him / her;
          </li>
          <li>
            (g) Personalize User’s experience on the Website / Mobile App by
            presenting advertisements, Products and offers tailored to the
            User’s preferences;
          </li>
          <li>
            (h) Customization, administration etc. of the Website / Mobile App,
            location of errors, Website / Mobile App testing, data analysis for
            the Website etc;
          </li>
          <li>(i) Provision of various services on the Website / Mobile App</li>
          <li>
            (j) To protect integrity of the Website / Mobile App, improve our
            platform, prevent or detect fraud or abuse of our Website / Mobile
            App;
          </li>
          <li>
            (k) To conduct analytical studies on various aspects including User
            behaviour, User preferences etc.
          </li>
          <li>
            (l) Enable third parties to carry out technical, logistical or other
            functions on our behalf;
          </li>
          <li>
            (m) To permit employees of the Company to contact Users, and enable
            them to implement the orders placed by Users, resolve User queries,
            issues, grievances etc.;
          </li>
          <li>
            (n) To further improve the product and/or services, to facilitate
            the servicing of User’s product and/or to give insight on User’s
            product experience.
          </li>
          <li>
            (o) To trace computer resources of any person for the purposes of
            determining compliance with the provisions of the Information
            Technology Act, 2000 and / or any other law for the time being in
            force.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          6. DISCLOSURE AND RETENTION OF USER INFORMATION
        </p>

        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500 flex flex-col gap-4">
          <li>
            6.1 Shri Lakshmi Water Purifier considers the Information about its
            customers as an important part of its business. Accordingly, Shri
            Lakshmi Water Purifier shall not engage in sale of Information
            relating to Users to third parties. However, Shri Lakshmi Water
            Purifier may share User information with third parties in the
            circumstances specified herein below, after reasonably assuring
            itself that such third parties have undertaken to maintain
            confidentiality of Personal Information relating to the Users
            <ul className="font-[sentient]  text-sm  mt-8 pl-16 px-8 w-[90%] text-gray-500 flex flex-col gap-2">
              <li>
                (a) Third Party Vendors / Service Providers etc.: Personal
                Information relating to Users may be made available to the third
                party Vendors, distributors etc. who sell their Products on the
                Website / Mobile App. Further, Shri Lakshmi Water Purifier may
                engage third party service providers to render various services,
                and perform various functions in relation to the business
                undertaken on the Website / Mobile App, and / or for the
                Purpose(s) discussed in Paragraph 6 above. For instance, Shri
                Lakshmi Water Purifier may engage third party service providers
                for maintenance of its Website / Mobile App, fulfilment of
                orders, delivery of packages, analysing data, providing
                marketing assistance, processing of credit card payments,
                provision of customer services etc. Such third party service
                providers / Vendors would have access to Personal Information of
                Users for the purpose of performing their functions / rendering
                their services etc.
              </li>
              <li>
                (b) Business Transfers: Shri Lakshmi Water Purifier may transfer
                or otherwise share some or all of its assets, including Your
                Information in connection with a merger, acquisition,
                reorganization or sale of assets or business or in the event of
                bankruptcy. Should such a sale or transfer occur, Shri Lakshmi
                Water Purifier will reasonably ensure that the Information You
                have provided and which we have collected is stored and used by
                the transferee in a manner that is consistent with this Privacy
                Policy. Any third party to which Shri Lakshmi Water Purifier
                transfers or sells as aforesaid will have the right to continue
                to use the information that You provide to us or collected by us
                immediately prior to the transfer
              </li>
              <li>
                (c) Government Agency: Shri Lakshmi Water Purifier may share any
                Information relating to Users (i) with Government agencies
                mandated under the law to obtain Information relating to Users
                from Shri Lakshmi Water Purifier; (ii) any third party, when
                Shri Lakshmi Water Purifier is required to disclose the same
                under an order of a government or judicial authority under any
                law for the time being in force, or where such disclosure is
                necessary for the compliance of a legal obligation.
              </li>
              <li>
                (d) With User Consent: Without prejudice to the aforesaid, Shri
                Lakshmi Water Purifier may disclose Personal Information
                relating to the User with his / her / its consent. For this
                purpose, Shri Lakshmi Water Purifier may send a prior notice to
                the User before sharing Personal Information relating to the
                User with third parties. In case no objection or intimation is
                received from the User, Shri Lakshmi Water Purifier would
                presume that User has granted its consent for sharing of said
                Information with third parties.
              </li>
            </ul>
          </li>
          <li>
            By using or visiting the Website / Mobile App and agreeing to the
            terms of this Privacy Policy, User shall be construed to have
            consented to, and accepted the disclosure of his Information to
            third parties as provided under this Privacy Policy.
          </li>
          <li>
            6.2 Shri Lakshmi Water Purifier will share Your Personal Information
            internally with such persons who need it to complete Your purchase
            or carry out Your instructions regarding the receipt of marketing
            information.
          </li>
          <li>
            6.3 Shri Lakshmi Water Purifier shall keep sensitive personal data
            or information for only as long as the purposes for which the
            information may lawfully be used or is otherwise required under any
            other law for the time being in force. For those customers who have
            registered for the mailing list, their personal information is kept
            until we are notified that they no longer want their information
            stored.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          7. SECURITY PRACTICES AND PROCEDURES FOR PROTECTION OF INFORMATION
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          In order to make every effort to ensure that Your experience on the
          Website / Mobile App is secure, we use encryption technology to
          protect You against the loss, misuse or alteration of Your personal
          information. When You fill out any contact forms or access Your
          account, a secure server encrypts all of Your information through the
          use of Secure Socket Layers (SSLs). To be sure You are browsing secure
          pages for transactions, check Your Web browser’s status bar (located
          at the bottom of the window) for the closed padlock icon. This icon
          appears in Your web browser to tell You that You are viewing a secure
          web page. Also, all browsers display an “s” after the “http”
          (https://) in the Web site address to indicate that You are in a
          secure environment.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          8. ACCURACY AND PROTECTION OF PERSONAL INFORMATION
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          Shri Lakshmi Water Purifier relies on Users to notify it of any
          changes in personal information. Should any inaccurate information
          come to Shri Lakshmi Water Purifier’s attention, it will investigate
          and correct the information and, if necessary, apprise the User of the
          change. Only those staff members who need the User’s personal
          information in order to respond to the User’s requests are given
          access to it. Employees are provided with training and information
          regarding the proper handling of personal information. All information
          stored in Shri Lakshmi Water Purifier’s computer system is protected
          from unauthorized access and information that is stored in document
          form is kept in secure locations to prevent access by unauthorized
          persons.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          9. USER DISCRETION AND THE CHOICE TO OPT OUT
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          You can block cookies by activating the setting on your browser that
          allow you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies), you may not be able to access all or parts of our
          site, or they may not function properly. The Users have a choice to
          opt-out of receiving non-essential (promotional, marketing related)
          communications from us, after setting up an account. In order to
          remove Your contact information, please visit Unsubscribe.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          10. GRIEVANCE OFFICER
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          In compliance with Information Technology Act, 2000 and rules made
          thereunder, the name and contact details of the Grievance Officer are
          provided below:
        </p>
        <ul className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          <li>
            <span className="font-bold text-black">Name</span>: Shri Lakshmi
            Water Purifier
          </li>
          <li>
            <span className="font-bold text-black">Address</span>:Shop No: 3,
            First Floor, Kodi Krupa Complex, Ananda Pura Circle, TC Palya Main
            Road, K.R.Puram, Bangalore - 560049
          </li>
          <li>
            <span className="font-bold text-black">Phone Number</span>:
            +91-7795453579
          </li>
          <li>
            <span className="font-bold text-black">Email</span>:
            shrilakshmiwaterpurifiers@gmail.com
          </li>
          <li>
            <span className="font-bold text-black">Time</span>:9:00 AM to 8:00
            PM
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          11. UPDATES TO THIS PRIVACY POLICY
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          We may periodically update the Privacy Policy. Please refer the
          Updated on legend at the top of this page to see when this Privacy
          Policy was last revised. Any changes to this Privacy Policy will
          become effective when we post the revised Privacy Policy on our
          Website / Mobile App. Your use of the Website / Mobile App following
          these changes means that you accept the revised Privacy Policy.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          12. GOVERNING LAW
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          The Website and Mobile App are designed and targeted to users who
          reside in the Republic of India and is governed by and operated in
          accordance with the laws of the India. By accessing and/or providing
          personal information through the Website / Mobile App, you confirm
          that you meet the legal requirements for disclosure of personal
          information in your jurisdiction.
        </p>
      </div>
      <Footer />
    </>
  );
}
