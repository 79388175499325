import React, { useEffect } from "react";
import Footer from "./Footer";

export default function TernsConditons() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="mt-40  ml-10 md:ml-20 mb-20" id="termsandconditions">
        <p className="font-[sentient] font-bold text-3xl text-skyhero">
          Terms of sale
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          IMPORTANT TERMS
        </p>
        <p className="font-[sentient]  text-sm  mt-8 px-8 w-[90%] text-gray-500">
          1.1 This terms of sale{" "}
          <span className="font-bold text-black">("Terms of Sale")</span>{" "}
          document is an electronic record in the form of an electronic contract
          formed under Information Technology Act, 2000 and rules made
          thereunder and the amended provisions pertaining to electronic
          documents / records in various statutes as amended by the Information
          Technology Act, 2000. This Terms of Sale document does not require any
          physical, electronic or digital signature and it is a legally binding
          document between the Buyer and the Vendor.
        </p>
        <p className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          1.2 For the purposes of this Terms of Sale document, wherever the
          context so requires on the Shri Lakshmi Water Purifiers Website
          <span className="font-bold text-black">(“Website”)</span> for sale and
          purchases of any goods and/or services
          <span className="font-bold text-black">("Products")</span>, the term,{" "}
          <span className="font-bold text-black">("Vendor")</span> shall mean
          Shri Lakshmi Water Purifiers; and a User who purchases Products using
          the Website is referred to as the.
          <span className="font-bold text-black">("Buyer")</span>
        </p>
        <p className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          1.3 By listing the Products on the Website, the Vendor makes an offer
          to sell the listed Products to the Buyer and once the Buyer agrees to
          buy a Product, the Buyer accepts the offer to sell made by the Vendor.
          These Terms of Sale will be effective and binding on the Vendor upon
          Vendor’s advertising, exhibiting and creating a listing of the product
          on the Website, and shall be effective and binding on the Buyer upon
          Buyer agreeing to purchase the Products so listed by the Vendor.
        </p>
        <p className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          1.4 The Buyer is requested to read these Terms of Sale carefully
          before buying any Product using the Website respectively. These Terms
          of Sale are to be read with the various agreements along with the
          Terms of Use, Privacy Policy and all other policies of the Website.
          The Buyers are requested not to make purchases using the Website if
          they are not agreeable to these Terms of Sale.
        </p>
        <p className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          1.5 These Terms of Sale may be revised from time to time, therefore
          the Buyer is requested to read these Terms of Sales carefully before
          purchasing a Product using the Website respectively. The Buyer’s
          continued use of the Website following such changes and modifications
          to these Terms of Sale will constitute Buyer’s acceptance of such
          changes or modifications.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          OFFER AND ACCEPTANCE OF THE PRODUCT
        </p>
        <p className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          2.1 The contract for sale of the Product is a bipartite contract
          between the Buyer and the Vendor. By listing the Product on the
          Website, the Vendor makes an offer to sell the Product to the Buyer
          and once the Buyer agrees to buy the Product listed by the Vendor, it
          accepts the offer to sell the Product made by the Vendor. The offer to
          sell the Product made by the Vendor is not an absolute and
          unconditional offer. The Vendor is entitled to repudiate the same at
          any time before the delivery of the Product to the Buyer without any
          liability or any obligation towards the Buyer.
        </p>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">PRODUCTS</p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li>
            3.1 The sale of the Products on the Website is governed by the
            description and specifications of the Product and the terms of
            warranties provided by the Vendors in addition to these Terms of
            Sale. The details of the warranty, if any, being provided by the
            Vendor of the Product shall be specified on the relevant Product
            webpage or on the Product label.
          </li>
          <li className="mt-4">
            3.2 Unless otherwise specified all payments by the Buyer shall be
            required to be made online. In specific cases, Vendor may make
            available other modes of payment to the Buyer. The details of such
            alternate modes of payment shall be made available on the Product
            webpage.
          </li>
          <li className="mt-4">
            3.3 The stock status of all the Products is shown on the Website as
            ‘In Stock’ or ‘Out of Stock’. However, sometimes a Product may not
            be available to be delivered to the Buyer after receipt of the order
            from the Buyer. This may happen when multiple orders are placed on
            the Website simultaneously. Hence, the final order status of the
            Product will be reflected on the Website as ‘Order Accepted’ after
            confirmation of availability of the Product is received from the
            Vendor.
          </li>
          <li className="mt-4">
            3.4 In case the Buyer’s order is cancelled for any reason after the
            payment has been processed, the amount received from the Buyer shall
            be returned, reversed or remitted back to the Buyer depending upon
            the mode of payment used by the Buyer to make the purchase.
          </li>
          <li className="mt-4">
            This document is published by Shri Lakshmi Water Purifiers in
            accordance with the applicable provisions of the Information
            Technology Act, 2000 and Rules thereunder that require publishing of
            the Terms of Use for access or usage of a website. Buyer’s use of
            the Website and services and tools are governed by Terms of Use as
            applicable to the Website including the applicable policies which
            are incorporated herein by way of reference. If Buyer transacts on
            the Website, it shall be subject to the policies, including "Terms
            Of Sale" document that are applicable to the Website for such
            transaction. By mere use of the Website, Buyer shall be contracting
            with Shri Lakshmi Water Purifiers and these terms and conditions
            including the policies constitute Buyer’s binding obligations, with
            Shri Lakshmi Water Purifiers.
          </li>
          <li className="mt-4">
            3.5 As regards the sale of Product (goods) by Vendor to the Buyer,
            the Vendor hereby represents and warrants to the Buyer that:
            <ul className="list-disc p-4">
              <li>
                Vendor has the right to sell the Products to the Buyer on or
                through the Website;
              </li>
              <li>
                Buyer shall have and enjoy quiet possession of the Products;
              </li>
              <li>
                {" "}
                Products shall be free from any charge or encumbrance in favour
                of third party;
              </li>
              <li>
                {" "}
                Buyer shall be entitled to all the warranties and other
                collaterals applicable to the Product or as generally made
                available by Vendor;
              </li>
              <li>
                Product shall meet the description and specifications as
                provided on the Website.
              </li>
            </ul>
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          SELLING PRICE
        </p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li className="mt-4">
            4.1 The Selling Price of the Product is provided on the Website on
            'as is' basis as provided by the Vendor and is subject to revision
            without notice.
          </li>
          <li className="mt-4">
            4.2 Unless stated otherwise, all prices are inclusive of GST and
            other taxes applicable under law.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          DELIVERY OF PRODUCTS
        </p>

        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li className="mt-4">
            5.1 Vendor takes responsibility to package and dispatch the Products
            to the Buyers. The risk of any damage, loss or destruction to the
            Products during the course of the delivery or during transit shall
            be that of the Vendor.
          </li>
          <li className="mt-4">
            5.2 Unless stated otherwise on the Product webpage, the delivery of
            the Products shall be done free of cost.
          </li>
          <li className="mt-4">
            5.3 The Vendor shall make best efforts to dispatch all orders within
            2 (Two) to 3 (Three) working days and have the same delivered within
            2 (Two) to 4 (Four) working days after the dispatch, contingent upon
            the location of the delivery. Any delay in dispatch of the Product
            shall be communicated to the Buyer through SMS/e-mail/voice call.
          </li>
          <li className="mt-4">
            5.4 Please note that there is no guaranteed dispatch time and any
            information about the dispatch time is estimate only and should not
            be relied upon as such. Therefore, time is not the essence of the
            bi-partite contract between the Buyer and the Vendor for purchase
            and sale of Product on or through the Website. However, the Product
            shall not be delivered to the Buyer unless the Buyer makes the
            payment of purchase of the Product.
          </li>
          <li className="mt-4">
            5.5 Buyer shall be bound to accept delivery, or to ensure acceptance
            of delivery, of the Products upon their delivery to the shipping
            address. The Vendor, or the logistic service provider engaged by the
            Vendor will only approach Buyer’s shipping address once, for
            delivery of the Products, and if there is nobody available at the
            shipping address to take delivery of the Product at the shipping
            address then, Buyer shall be liable to the Vendor for such
            non-acceptance of the Products delivered to it. The Vendor may levy
            and deduct such costs, damages, penalties etc. to compensate for
            non-acceptance of the Products delivered to Buyer by the Vendor.
          </li>
          <li className="mt-4">
            5.6 The title in the Products and other rights and interest in the
            Products shall directly pass on to the Buyer from Vendor upon
            delivery of such Product and upon full payment of price of the
            Product. Upon delivery, the Buyer is deemed to have accepted the
            Products. The risk of loss shall pass on to the Buyer upon delivery
            of Product.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          ORDER CANCELLATION
        </p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li className="mt-4">
            • 6.1 The Buyer can cancel any order only before dispatch of goods
            by the Vendor. The Buyer may cancel any order before dispatch, by
            getting in touch with the Vendor’s Customer Care service team on
            their e-mail id care@srilakshmispares.com/
            Shrilaksmiwaterpurifiers@gmail.com within 48 hours of placing the
            order. Any payment made by the Buyer for such an order shall be
            refunded to the Buyer within 7(Seven) to 10(Ten) working days of
            receiving the request for cancellation.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          GENERAL TERMS ABOUT RELATIONSHIP WITH BUYER
        </p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li className="mt-4">
            7.1 The contract for sale of any of the products shall be a
            bipartite contract between the vendor and the buyer.. The title in
            the products and other rights and interest in the products shall
            directly pass to the buyer from the vendor. e.
          </li>
          <li className="mt-4">
            7.2 These terms of sale shall not amend or modify any agreements,
            contracts, terms or policies between the Vendor or Buyer
          </li>
          <li className="mt-4">
            7.3 The products shall be subject to Vendor's terms and conditions
            for warranty, service and maintenance.
          </li>
          <li className="mt-4">
            7.4 Vendor shall not assume any liability for the non-availability
            of the product or delivery of the product once dispatched through a
            third party delivery service.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          MISCELLANEOUS
        </p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500">
          <li className="mt-4">
            8.1 Events beyond Reasonable Control (Force Majeure). The Vendor
            will not be responsible for any delay or failure to comply with
            these Terms of Sale or the additional policies if the delay or
            failure arises from any event which is beyond the Vendor’s
            reasonable control. Such events would include (but are not limited
            to) fires, floods, earthquakes, storms, natural disasters, war, ,
            pandemics, epidemics, civil unrest, acts of terrorism or malicious
            damage to or destruction of premises, equipment or goods or by
            reason of any act done pursuant to a trade dispute or shortages of
            labour.
          </li>
          <li className="mt-4">
            8.2 Invalidity. In the event that one or more of the terms set out
            in these Terms of Sale or in the additional policies is held to be
            invalid by a competent authority, the remaining terms of the Terms
            of Sale and the additional policies shall continue to have effect
            and Buyer will still be bound by them.
          </li>
          <li className="mt-4">
            8.3 Privacy. Personal information, such as Buyer’s contact details,
            that it provides to Vendor during the order process will be kept and
            used by Vendor in accordance with its Privacy Policy.
          </li>
          <li className="mt-4">
            • 8.4 Notices and other communications. Buyer will be required to
            provide a valid phone number and e-mail at the time of placing its
            order with Vendor. Vendor may communicate with Buyer by e-mail, SMS,
            phone call or by posting notices on the Website or by any other mode
            of communication. Buyer hereby consents to receive all notices and
            communications from Vendor by the modes mentioned herein. Buyers are
            required to use only the specific email
            id(Shrilakshmiwaterpurifers@gmail.com) / phone number(7795453579)
            given by them at the time of placement of the order for
            communications with Vendor.
          </li>
          <li className="mt-4">
            8.5 Governing Law. These Terms of Sale shall be governed in
            accordance with the laws of India. All disputes arising out of these
            Terms of Sale shall be submitted to the exclusive jurisdiction of
            the courts at Delhi, India.
          </li>
        </ul>
        <p className="font-[sentient]  text-xl text-skyhero mt-4">
          TERMS & CONDITIONS OF SERVICE
        </p>
        <ul className="font-[sentient]  text-sm mt-4 px-8 w-[90%] text-gray-500 list-disc">
          <li className="font-bold mt-4">
            Definitions
            <ul className="font-normal list-decimal mt-2">
              <li>
                1. Prospective Customer; meaning a person who is may avail Shri
                Lakshmi Water Purifiers
              </li>
              <li>
                Service(s): Providing an online platform to buy smart sleep
                products.
              </li>
              <li>
                3. Subscriber(s), meaning a prospective customer who has agreed
                to avail the services of Shri Lakshmi Water Purifiers by
                accepting the Terms and Conditions below
              </li>
            </ul>
          </li>
          <li className="font-bold mt-4">
            Service as provided
            <ul className="font-normal list-decimal mt-2">
              <li>
                1. The services provided by Shri Lakshmi Water Purifiers are in
                accordance with the Law.
              </li>
              <li>
                In pursuance of the service/activity of providing an online
                platform for the customers to buy smart sleep products.
              </li>
              <li>
                Shree Lakshmi Water Purifiers, shall make voice calls and send
                transactional messages/ emails to such interested Potential
                Customers, informing them of the Services and activities.
              </li>
            </ul>
          </li>
          <li className="font-bold mt-4">
            Terms and Conditions pertaining to Service
            <ul className="font-normal list-decimal mt-2">
              <li>
                1. The Subscribers have agreed to Shri Lakshmi Water Purifier’s
                Terms of Service and have registered themselves with it through
                online or offline means to use its Service whether by mobile
                application or through the website
                care@srilakshmispares.com or shrilakshmiwaterpurifiers@gmail.com;
              </li>
              <li>
                The Subscribers undertake that they do not object to receiving
                calls, emails and messages purely transactional in nature
                relating to receiving information about the Service and
                activities regardless of DND status or being enlisted in
                National Customer Preference Register;
              </li>
              <li>
                The Subscribers undertake that specific approval is granted to
                Livpure Smart Homes Pvt. Ltd. to receive
                calls/emails/transactional messages after receipt of a
                verifiable request from the Subscriber to Livpure Smart Homes
                Pvt. Ltd.
              </li>
              <li>
                The Subscribers agree they shall be provided information through
                calls/ messages/e-mails or any others means permissible and may
                opt out at any time during such period from receiving such
                information;
              </li>
              <li>
                The Subscribers agree that they shall, at least once in 180 days
                through calls/ messages/e-mails or any others means permissible,
                be informed about the procedure to opt out or subscribing from
                receiving such information;
              </li>
              <li>
                The Subscriber agrees that it has been informed regarding
                procedure to opt out/unsubscribe from receiving such information
                in every advertisement wherein the details of its Services are
                published
              </li>
              <li>
                Lakshmi Water Purifier’s Shri reserves the right to exercise due
                diligence on the Subscribers and Prospective Customers.
              </li>
              <li>
                The Subscriber shall indemnify Livpure Smart Homes Pvt. Ltd for
                any loss or damage that may be caused to it or any of its
                directors, associates, employees, shareholders or personnel due
                to the breach of the terms and conditions contained herein.
              </li>
            </ul>
          </li>
          <li className="font-bold mt-4">
            Other Terms and Conditions
            <ul className="font-normal list-decimal mt-2">
              <li>
                The Services and Terms and Conditions shall be subject to all
                applicable laws, rules and regulations which are in existence
                and which may be promulgated anytime by any statutory authority.
              </li>
              <li>
                No obscene, objectionable or unauthorized content shall be
                included in transactional calls/messages/emails which is against
                public interest or national security.
              </li>
              <li>
                By subscribing to the Services, Subscribers agree to be bound by
                these terms and conditions.
              </li>
              <li>
                Shri Lakshmi Water Purifier’s reserves the right, at any time,
                without prior notice and without assigning any reason
                whatsoever, to add/alter/modify/change or vary these terms and
                conditions or to replace, wholly or in part, or to extend or
                withdraw it altogether.
              </li>
              <li>
                5. Any disputes arising out of the Terms & Conditions shall be
                subject to arbitration by a sole arbitrator to be appointed by
                Shri Lakshmi Water Purifier’s for this purpose. The proceedings
                of the arbitration shall be conducted as per the provisions of
                Arbitration and Conciliation Act, 1996. The place of arbitration
                shall be at New Delhi and language of arbitration shall be
                English. The existence of a dispute, if any, shall not
                constitute a claim against Shri Lakshmi Water Purifier’s
              </li>
              <li>
                This document is an electronic record in terms of Information
                Technology Act, 2000, and the Rules there under as applicable
                and the amended provisions pertaining to electronic records in
                various statutes as amended by the Information Technology Act,
                2000. This electronic record is generated by a computer system
                and does not require any physical or digital signatures.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
