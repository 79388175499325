import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./components/Homepage/Homepage";
import UpNavbar from "./components/Navbar/UpNavbar";
import {
  HashRouter,
  Routes,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { Route } from "react-router-dom";
import ThanksPage from "./components/Homepage/ThanksPage";
import TernsConditons from "./components/Homepage/TernsConditons";
import ProductReturnPolicy from "./components/Homepage/ProductReturnPolicy";
import PrivacyPolicy from "./components/Homepage/PrivacyPolicy";
import ReactGA from "react-ga";
import { useEffect } from "react";

ReactGA.initialize("G-TTNS4432ZN");

function App() {
  const location = window.location;
  // If using React Router

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <HashRouter>
      <UpNavbar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/thankspage" element={<ThanksPage />} />
        <Route path="/terms" element={<TernsConditons />} />
        <Route path="/return-policy" element={<ProductReturnPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </HashRouter>
    // <>
    // <Homepage/>
    // </>
  );
}

export default App;
